
/* Header Section */

  .header{
    background-color: #044cac;
    display: flex;
    height: 8vh;
    align-items: center;
    justify-content: space-between;
    padding-left: 1vw;
    padding-right: 1vw;
    width: 100%;
  }
  .headericons {
    color: white;
    height: 2rem;
    width: 2rem;

  }
 .headericons-right{
    color: white;
    height:1.5rem;
    width: 1.75rem;
    align-self: center;
    margin-left: 1vw;
    
  }
  .line{
    color: white;
    align-self: center; 
    margin-left: 1vw;
    height: 5rem;
    width: 2rem;
  
  }
  .left{
    
    display:flex;
    align-items: center;
    gap:1.3vw;
   
  }
  .left div{
    display: flex;
    align-items: center;
    gap:0.5vw;
  }
  .header .left div p {
  
    white-space: nowrap;
    margin: 0;
    font-family:'montserrat';
    color: white;

  }
  .header .right p {
   margin: 0;
   font-family:'montserrat';
    color: white;
  }
  .right{
    align-items: center;
    display: flex;
  }
  .header-button{
    background-color: white;
    border: none;
    color: #044CAC;
    padding: 1.6vh 3vh;
    margin-right: 2vw;
    font-family: montserrat;
    font-weight: 500;
    border-radius: 5px;
  
  }

  @media(max-width : 480px){
    .header{
      display: flex;      
      width: 100%;
      height:12vh;
      font-size: .8rem;
      justify-content:space-evenly;
      gap:12vw;
      padding: 0 2vw;
    }
    
    .headericons {
      height:1.2rem;
      width: 1.2rem;
     
    }
    
    .right p{
      display: none;

    }
    
    .line {
      display: none;
    }
    .headericons-right{
      display: none;
    }
 
    .left{
      display:flex;
      flex-direction: column;
      align-items:flex-start;
      gap:1vw;
    }
    .left div{
      display: flex;
      gap:1vw;  
      
    }
   
    .header-button{
      
      padding: 1.6vh 2.5vh;
      /* margin-right: 2vw; */
      border-radius: 5px;
      font-size: 13px;
      white-space: nowrap;
    }

  }

/* header section end */


/* p{
    font-family:'montserrat';
    color: white;
  } */

/*  navbar section start */

/* .overlay {
  display: none; /
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
  justify-content: center;
  align-items: center;
}

.overlay.active {
  display: flex;
}  */

  .navbar-list{
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 5vw;  
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 1rem;
    z-index: 5;
  
  }
 
  .Logoportion{
     
    height: 12vh;
    display: flex;
    align-items: center;  
    justify-content: space-between;
    padding-left: 1vw;
    padding-right: 1vw;
   
    /* position: fixed; */
  }
  

  .logo{
    height: 7vh;
    width:22vw;
   
  }
  
  .hamburger {
    display: none; 
    cursor: pointer;
    padding: 1vw;
    color: #044CAC;
  }

  .link:hover,
  .link:active{
    color : #1C8FE3;
    
  }
  
  .link{
    text-decoration: none;
    color:#044CAC;
  }
  

 @media (max-width: 480px){

  .logo{
    height: 12vw;
    width:70vw;
    
  }
  .Logoportion{
    display: flex;
    align-content: center;
    justify-content: space-between;
    height: 15vh;
    
  }

  .navbar-list {
    display: none; /* Hide navbar links by default on smaller screens */
    flex-direction: column;
    position: absolute;
    top: 50px; /* Adjust based on your header height */
    left: -90vw;
    width: 90vw;
    background-color: white;
    padding: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    font-size: 0.9rem;
    gap:20px;
    align-items: center;
    justify-content: center;
  }
  .navbar.active .navbar-list {
    display: flex; /* Show navbar links when active */
  }
  .hamburger {
    display: flex; /* Show hamburger menu icon */
    padding-left:8vw;
  }
 
 } 

/* navbar section end */

/* hero-section */

.hero-section{
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap:vw; */
  margin-top: 3%;
}
.hero-section-left{
  width: 52%;
  color: #044CAC;
  font-family: 'montserrat';
  margin-left: 5vw;
}
.hero-section-left h1{
  font-weight: 700;
  font-size: 48px;
  text-transform: uppercase;
  letter-spacing: 1.5px;

}

.hero-section-left h2{
  font-weight: 600;
  font-size: 22px;
  font-family: 'montserrat';
   text-align: left;
  width:80%;
  color: #044CAC;
}
.hero-section-left h3{
  font-weight: 500;
  font-size: 18px;
  margin-top: 25px;
  line-height: 30px;
  width:80%;
  color: #3C84E4;
}
.hero-section-left button {
  margin-top: 20px;
  background-color: #044CAC;
  color: white;
  padding: 10px 40px;
 margin-right: 20px;
  border-radius: 100px;
  border: none;
}
.hero-section-left button:hover{
  background-color: #044CAC;
}
   
  .mainimage{
    width: 1440px;
    height: 620px;
    opacity: 90%;
    
  }

  
  video{
  width:600px;
  height:380px;
  margin-top: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-right: 3vw;
  }
  .why-video-container video {
    width:500px;
    height:300px;
    margin-top: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* margin-right: 3vw; */
  }


@media(max-width:480px){
  
  .hero-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .hero-section-left{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .hero-section-left h1{
  text-align: center ;
  font-size:2.5rem;
  letter-spacing: 1.2px;
  line-height: 5vh;
  width:80%;
 margin: 0;
 display: flex;
 justify-content: center;
  }

  .hero-section-left h2{
    text-align: center;
    width: 88%;
  }
  .hero-section-left h3{
    text-align: center;
    width: 90%;
  }
  video{
    
    width: 100%;
    height:100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 10%;
  }

  .video-container{
    display: flex;
    justify-content: center;
   
  }


}





  /* About us Section */

 .about-us{
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  gap: 20px;
  font-family: 'montserrat';
  margin-top: 10vh;
 
 } 
 .about-us div{
  background-color: #044CAC;
  height: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;
 }
 .about-us h1{
  font-size: 26px;
  font-weight: 600;
  color: white;
  width: 65vw;
  text-align: center;
  line-height: 40px;
  
 }
 .about-us h2{
  font-size: 35px;
  font-weight: 500;
  color: black;
  font-size: 20px;
  text-align: center;
  width: 70vw;
  justify-self: center;
  line-height: 30px;
  margin: auto;
 }
 .about-us h3{
  font-size: 20px;
  font-weight: 600;
  color: #044CAC;
  font-size: 22px;
  text-align: center;
  justify-self: center;
  margin: auto;
  width: 50vw;
  line-height: 30px;
 }

@media(max-width:480px){


  .about-us div{
    height: auto;
    width: 100%;
  }

.about-us h1{
font-size: 1rem;
line-height: 5vh;
padding:1vh;
width: 90%;
}

.about-us h2{
  font-size: 1em;
  width: 90%;
}
.about-us h3{
  font-size: 1em;
  width: 90%;
}

}




/* Feature Section */

.section-heading{
  background-color: #044CAC;
  color: white;
  padding: 10px 30px;
  font-family: 'montserrat';
  font-size: 28px;
  font-weight: 500;
  width: 20%;
  border-radius: 50px;
  text-align: center;
}

.feature-section{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  margin-top: 8vh;
  
}
.feature-section div{
  display: flex;
  align-items: center;
  justify-content: center;
  gap:20px;
}
.feature-card{
display: flex;
font-family: 'montserrat';
padding:5px;
width:500px;
height:150px;
}

.feature-card img{
  width:20%;
  height: 53%;
}
.feature-card div {
  display: flex;
  flex-direction: column;
  gap:5px;
}
.feature-card div h2{
  color:#044CAC;
  font-size: 22px;
  font-weight: 600;
  width:90%;
  font-family: 'montserrat';
  text-align: left;
}
.feature-card div p{
  color:#044CAC;
  font-size: 17px;
  width: 90%;
  font-weight: 500;
  font-family: 'montserrat';
}

@media(max-width:480px){

  .section-heading{
  font-size : 1em;
  border-radius:  10px;
  width: 40%;
  }
  .feature-section{
    display: flex;
    flex-direction: column;
  }
 .feature-section div{
  display: flex;
  align-items: center;
  flex-direction: column;

 }
 .feature-card{
   margin-top: 2vh;
  display: flex;
  flex-direction: row; 
 }
 .feature-card img{
  width:55px;
  height:55px;

  
 }
 .feature-card div {
  display: flex;
  flex-direction: column;
  gap:0px;
  width:50%;
  
}
 .feature-card div h2{
  font-size:15px;
  text-align: center;
 }
 .feature-card div p{
  font-size: 12px;
  text-align: center;
  margin-top: 0;
 }

}

/* Free Demo section */

.demo-section{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 10vh;
  background-color:#f5f5f5 ;
  padding: 6vh 0;
}

.demo-section h1{
  font-weight: 600;
  font-family: 'montserrat';
  color:#044CAC;
  font-size: 26px;
  text-align: center;
  width: 70vw;
  line-height: 35px;
  
}
.demo-section div{
  background-color: #3C84E4;
  width: 10%;
  height: 5px;
  
}

.demo-section button {
  background-color: #044CAC;
  color: white;
  font-family: 'montserrat';
  font-size: 24px;
  font-weight: 500;
  padding: 8px 25px;
  border: none;
  margin-top: 15px;
}

@media(max-width:480px){
  .demo-section h1{
    font-size: 1.2em;
    width:90%;
  }
  .demo-section button{
    font-size: 1em;
  }
}

/* Beneft-section */

.benefit-section{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  margin-top: 8vh;
  background-color: #f5f5f5 ;
  /* padding: 10vh; */
  
}

.benefit-section div{
  display: flex;
  align-items: center;
  justify-content: center;
  gap:20px;
}


@media(max-width:480px){
 .benefit-section div{
  display: flex;
  flex-direction: column;
 }

}



/* why us section */

.why-us{
  display: flex;
  gap: 8%;
  align-items: center;
  justify-content: center;
  margin-top: 8%;
}

.why-us div{
  width:40%;
  font-family: 'montserrat';
}

.why-us h1{
 font-size: 46px;
 color: #044CAC;
 font-weight: 700;
 width: 90%;

}
.why-us h2{
  font-size: 18px;
  color: #044CAC;
  font-weight: 500;
  margin-top: 5vh;
  line-height: 4vh;
  width:95%;
  text-align: left;
 }
 .why-us ul{
  font-size: 16px;
  color: #044CAC;
  font-weight: 500;
  margin-top: 5vh;
  line-height: 3vh;
  display: flex;
  align-items: center;
  width:95%;
  line-height: 5vh;
  text-align: left;

 }

 @media(max-width:480px){
  .why-us{
    display: flex;
    flex-direction: column;

  }
  .why-video-container{
    display: flex;
    align-items: center;
    justify-content: center;
    display:none;
  }
  .why-video-container video{
    display:none;
  }
  .why-us h1{
    font-size:1.5em;
    text-align: center;
  }

  .why-us div{
    width:90%;
  }
  .why-us h2{
    font-size: 0.9em;
    line-height: 4vh;
    text-align: center;
  }
  .why-us div ul{
    font-size: 0.9rem;
    line-height: 4vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
  }

 }

/* Pricing Plan Section */

.pricing-plan{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-family: 'montserrat';
  margin-top: 15vh;
}
.pricing-plan thead{
  background-color: #044CAC;
  color: white;
  font-family: 'montserrat';
  width: 80%;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-heading{
  font-size: 30px;
  white-space: nowrap;
  font-weight: 600;
}
.pricing-plan thead tr{
display: flex;
align-items: center;
justify-content: center;
margin-left: 7vw;
}

.pricing-plan tr{
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-align: center;
  padding:0.5vh;
  width: 80%;
  gap:20vh;
}
td {
  width:420px;
  display: flex;
  align-items: center;
  justify-content:center;
  font-weight: 600; 
}

.quote-row{
  height: 10vh;
}
.pricing-plan tr td img{
  width:12%;
}
.special-price table{
display:flex ;
flex-direction: column;
align-items: center;
justify-content: center;
}
 .odd-row{
  background-color: #f5f5f5;
 }

@media(max-width:480px){
  .pricing-plan{
    width: 100%;
    overflow-x: hidden;
    margin-top: 10vw;
  }
.pricing-plan thead{
  height:auto;
  width:95%;
}

.th{
  display: flex;
  align-items: center;
  justify-content: center;
 padding-left: 5vw;
 width: 50%;
}
.pricing-plan thead tr {
display: flex;
align-items: center;
justify-content: space-between;
white-space: nowrap;
margin: 0;
/* padding-right: 4vw; */
gap:6vw;


}
.table-heading{
font-size: 0.9rem;
font-weight: 400;
white-space: nowrap;

}
.heading-1{
  margin-left: 2vw;
}
.table-heading heading-1{
  margin-left: 0;
  padding-left: 0;
}
  .pricing-plan tr{
  
    width: 95%;
    height: 30%;
   
  }
  .table-row{

    font-weight: 600;
    text-align: center;
    padding:0vh;
    gap:0.8vw;
     display: flex;
    align-items: center;
    justify-content: center;
    height:50px;
    width: 100%;
    padding-left: 1vw;
    padding-right: 1vw;
  }
  td {
    /* width:42px; */
    font-size: 0.7rem;
    font-weight: 600; 
   /* text-wrap: normal; */
   width:210px;
   display: flex;
   align-items: center;
   justify-content: center;
   text-align: center;
 width:26vw;
 padding-left: 1vw;
 padding-right: 1vw;
  }
  .special-price table{
    display:flex ;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
    }
    
    .pricing-plan tr td img{
      width:25%;
    }
    

}

/* our clients section */
.clients-section-div{
margin-top: 10vh;
}
.client-logo-slider {
  width: 80%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  /* gap:10px;
  margin-top: 10px; */
}

.client-carousel {
  width: 100%;
  overflow: hidden;
}

.client-track {
  display: flex;
  transition: transform 1s ease-in-out;
  /* width: calc(20% *25); */
}

.client {
  flex: 0 0 20%; /* Shows 10 logos at a time */
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap:20px;
}

.client img {
  width: 70%;
  height: auto;
  border-radius: 5px;
  display: flex;
  align-items: center;
}


/* 
button {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  cursor: pointer;
  font-size: 2rem;
  padding: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

button.prev {
  left: 0;
}

button.next {
  right: 0;
}

button:hover {
  background-color: rgba(0, 0, 0, 0.7);
} */




/* Our Clients :- Testimonial Section */

.client-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10vh;
  gap:2vh;
}

.client-section h1{
font-family: 'montserrat';
font-size: 40px;
font-weight: 700;
color: #044CAC;

}

.client-section div{
  background-color: #3C84E4;
  width: 10%;
  height: 5px;
  
}
.quotaion-image{
  background-image: url("images/quotation.jpg");
 
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
 height: 120vh;
 width:100%;
 display: flex;
 align-items: center;
 justify-content: center;
 /* background-size: auto; Resize the background image to cover the entire container */
 background-size: 1300px 600px;
}
.carousel {
  position: relative;
  width: 80%;
  max-width: 800px;
  margin: auto;
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* margin-top: 50vh; */
  height:400px;
}
.carousel-container {
  overflow: hidden;
}
.carousel-items {
  display: flex;
  /* transition: transform 0.5s ease-in-out; */
  align-items: center;

}
.testimonial-card {
  min-width: 100%;
  margin-top: 13vh;
  padding: 25px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'montserrat';
  gap:20px;

}
.testimonial-card div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.client-image{
  width: 200px;
  height: 180px;
}
.client-name {
  font-weight:600;
  margin-bottom: 10px;
  color: #044CAC;
  font-size: 22px;
  margin-top: 8vh;
  text-align: center;
}

.testimonial-text {
  font-style: italic;
  font-size: 16px;
  font-weight: 400;
  width: 26vw;
  line-height: 4vh;
  text-align: center;
}

.prev, .next {
  position: absolute;
  top: 50%;
  background: #dedede;
  color: white;
  border: none;
  padding: 10px 18px;
  cursor: pointer;
  z-index: 1;
  border-radius: 50%;
  transform: translateY(-50%);
}

.prev {
  left: 20px;
}

.next {
  right: 20px;
}

.prev:hover, .next:hover {
  background: grey 80%;
}

/* Responsive */
@media (max-width: 768px) {
  .carousel {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .testimonial-card {
    padding: 15px;
  }

  .prev, .next {
    padding: 6px 12px;
  }

  .client-logo-slider{
    width:95%;
  }

  .client-section{
    margin-top: 6vh;
  }
  .client-section h1{
    font-size: 24px;
   }
 
   .client{
    flex: 0 0 40%;
   }
   .client img {
    width: 90%;
    height: 75%;
  }

  .testimonial-text {
    font-style: italic;
    font-size: 0.8rem;
    font-weight: 400;
    width: 70vw;
    line-height: 4vh;
    /* text-align: center; */
  }
  .client-image{
    width: 120px;
    height: 120px;
  }
  .client-name {

    font-weight:500;
    font-size: 1rem;
    margin-top: 2vh;
   
  }

  .testimonial-card {
    width: 100%;
    flex-direction: column;
    font-family: 'montserrat';
    gap:5vw;
    margin-top: 4vh;
  }
  
  .carousel {
    position: relative;
    width: 90%;
   margin-top: 8vh;
   height: 66vh;
  }
  .quotaion-image{
  background-image: none;
    
    height: 80vh;
    width:100%;
    /* margin-top: 5vh; */
  }

  

}



 

 /* partners Section */


.partners-images{
  display: flex;
  align-items: center;
  justify-content: center;
  gap:18vw;
  margin-top: 12vh;
  margin-bottom: 30vh;
}
.partners-images img{
  width:15%;
  height:auto
}

@media(max-width:480px){

  .partners-images{
    margin-top: 12vh;
   margin-bottom: 12vh;
   flex-direction: column;
   gap:10vw;
  }
  .partners-images img{
    width:33%;
  }
}

/* faq section */


body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 20px;
}

.faq-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 10vh;
  font-family: 'montserrat';
  font-weight: 500;
}


.faq-items {
  margin-top: 20px;
}

.faq-item {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
}

.faq-question:hover {
  background-color: #f1f1f1;
}

.faq-icon {
  font-weight: bold;
  font-size: 24px;
  color: #044CAC;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  padding: 0 10px;
  color: #044CAC;
}

.faq-answer.active {
  max-height: 200px; /* Adjust as needed */
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
}

/* Responsive */
@media (max-width: 768px) {
  .faq-container {
    width: 90%;
  }

  .faq-question {
    font-size: 16px;
  }

  .faq-icon {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .faq-question {
    font-size: 13px;
  }

  .faq-icon {
    font-size: 14px;
  }
  .faq-answer{
    font-size: 13px;
  }
}

/* Query Form Section */

/* Get Touch Setion */
  
.get-touch{
  display:flex;
  align-items: center;
  justify-content: center;
  height:50%;
  margin-top: 10vh;
  gap:5%;
}
.message{
  color:#3C84E4;
  font-family: 'montserrat';
}
.get-heading{
  color: #3C84E4;
  font-family: 'montserrat';
  font-weight: 700;
}
.get-context{
  color: black;
  font-family: 'montserrat';
  font-weight: 400;
}
.get-align{
  
  margin-right: 80px;
  line-height: 100%;
}
.map{
  margin-right:5vw;
  margin-top: -10vh;
}

.query-form-container {
  max-width: 500px;
  max-height:100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-family:'montserrat';
  font-weight: 400;
  margin-bottom: 10vh;
}

.modal-title{
  color: #3C84E4;
  font-family: 'montserrat';
  font-weight: 600;
}

.query-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
  font-family:'montserrat';
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group textarea {
  resize: vertical;
  height: 100px;
}

button[type="submit"] {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #3C84E4;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family:'montserrat';
}

button[type="submit"]:hover {
  background-color: #044CAC;
}

.success-message {
  color: green;
  margin-top: 15px;
  text-align: center;
}

.error-message {
  color: red;
  margin-top: 15px;
  text-align: center;
}

/* Responsive */
@media (max-width: 768px) {
  .query-form-container {
    width: 90%;
  }
}

@media (max-width: 480px) {

  .get-touch{
    flex-direction: column;
    gap:8vh;
  }
  .message{
    margin:0;
  }
  .get-heading{
    font-size:1.8rem;
    margin-top: 1vh;
    padding: 0;
  }
  .get-context{
    font-size: 0.9rem;
  }
  .query-form-container {
    padding: 15px;
  }
  .form-group label{
    font-size:0.9rem;
  }
  button[type="submit"] {
    padding: 8px;
    font-size: 0.9rem;
  }
  .map-size{
    width:80vw;
    height:50vh;
  }


}

 
/* Footer Section */

.footer-portion{
  background-color: #333333;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap:10vh;
 
}
.footer-link{
  display: flex;
  margin-top: 10vh;
  gap: 45vw;

}
.address{
  width:25vw;

 
 
}
/* .copyright-section p{
  text-align: center;
  margin-top: 10px;
  color: white;
} */
.useful-link{
  margin-top: 2vh;
  color: white;
  font-family: 'montserrat';
  list-style-type: none;
  font-size:16px;
  padding: 0;
  line-height: 35px;
  font-weight: 300;

}

.footers-link{
  text-decoration: none;
    color:white;
}
/* .footer-left{
  
  display: flex;
  flex-direction: column;
  
  font-family: 'montserrat';

} */
.footer-left h4{
  color: white;
  font-size: 20px;
  font-weight: 500;
 
  
}

.footer-right{
  display: flex;
  flex-direction: column;
 justify-content: left;
} 
.footer-right h4{
  color: white;
  font-size: 20px;
  font-weight: 500;
 margin-left: 3.8vh;
}
.footer-portion h3{
  color: white;
  font-size: 16px;
  font-family: 'montserrat';
  margin-bottom: 6vh;
  font-weight: 300;
}
.footer-social-link{
  display: none;
  align-items: center;
  
}
.footer-social-link h3{
  margin: 0;
}
.linkage{
  text-decoration: none;
  color: inherit;
 color: white;
 }
 
@media(max-width:480px){

  .footer-portion{
    height:auto;
    margin-top: 3vh;
  }
 .footer-link{
  flex-direction: column;
  gap:3vw;

  }
  .footer-left h4, .footer-right h4{
    font-size: 1.2rem;
  }
  .footer-left , .footer-right{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:100%;
  }
  .footer-right .useful-link{
    gap:5vw;
  }
  .useful-link{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    font-weight: 300;
  }
  
  .address{
    width:60vw;
    text-align: center;
   
  }
  .footer-portion h3{
    font-size:1rem;
    
    width:70vw;
    text-align: center;
    line-height: 5vh;
    margin-top: 0;
  }
  .footer-social-link{
    display: flex;
    flex-direction: column;
    margin-top: 2vh;
  }
  .footer-social-link div{
    margin-top: 4vh;
    display: flex;
    gap: 7vw;
   
  }
  .footericons-right{
    color: white;
    height:1.8rem;
    width: 2rem;
    align-self: center;
    margin-left: 1vw;
    
  }
  .footer-social-link h3{
    font-size:1.2rem;
    font-weight: 500;
  }
  
}


/*  Whatsapp icon */

.whatsapp-button{
  background-color: transparent;
  border: none;
}
.whatsapp-button img{
  width: 75px;
  height: 75px;
}

.whatsapp-chat {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 350px;
  height: 500px;
  border: 2px solid #25d366;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1000;
}

.whatsapp-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #25d366;
}

.close-button:hover {
  color: #128c7e;
}

.scroll-button-alignment{
  display: flex;
  flex-direction: column;
  gap:10px;
  justify-content: center;
  align-items: center;
}

.scroll-to-top {
  position: fixed;
  bottom: 70px;
  right: 30px;
  z-index: 99;
}

.scroll-button {
  display: inline-block;
  border: none;
  outline: none;
  background-color: #044CAC;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 100px;
  opacity: 0.9;
  height: 60px;
  width: 60px;
 
}

.scroll-button:hover {
  background-color: #333;
}

.top-arrow-style{
  height:30px;
  width:30px;
}


@media(max-width:480px){

  .scroll-button{
    width:45px;
    height:45px;
  }
  .top-arrow-style{
    height:15px;
    width:15px;
    margin-bottom: 12px;
  }
  .whatsapp-button img{
  width:55px;
  height:55px;

  }
  .scroll-to-top {
    position: fixed;
    bottom: 70px;
    right: 20px;
    z-index: 99;
  }
  
}









/* this is additional css */
  .content{
    color:#1C8FE3;
    font-family:'poppins';
    font-weight: 600;
    font-size: 40px;
    margin-top: 25px;
  }
  .bodytext{
    color:black;
    font-family: 'poppins';
    font-size: 15px;
    text-align: center;
    margin-top: 0px;
  }
  
  .heading{
    color:black;
    font-family: 'poppins';
    font-size: 32px;
    font-weight: 500;
    
  }
  
  .readmore{
    color:#1C8FE3;
    font-family: 'lato';
    font-size: 15px;
  }
  
  .first-content{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height :375px;
  line-height: 10px;
    
  }
  .paraspace{
    line-height: 150%;
    width: 900px;
    margin-left: auto;
    margin-right: auto;
    
  }
  .service-section{
    height:800px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
   
    
  }
  .service-heading{
    color : #044CAC;
    font-family: 'montserrat';
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    /* margin-left: 5vw; */
  }
  .service-heading span{
    color:#1c8fe3;
  }
  .service-text{
    font-family: 'Poppins';
    font-size: 15px;
  text-align: center;
  color: black;
  
  }
  
  
  .card1{
    background-color: white;
    height: 186px;
    width: 420px;
   text-align: center;
   justify-content: center;
   line-height: 80%;
   margin-top: 18px;
   margin-left: 18px;
  }
  .account-logo{
    height:63px;
    width:48px;
   /* fill effect from img svg file */
    margin-top: 20px;
  }
  .card-heading{
    color:black;
    font-family: 'poppins';
    font-size: 24px;
    font-weight: 600;
  }
  .card-text{
    color: black;
    font-family: 'poppins';
    font-weight: 500;
    font-size: 12px ;
    width:342px;
    text-align: center;
    margin-left: 40px;
  }
  .service-line-height{
    line-height: 150%;
  }
  .card-align{
    display: flex;
    align-items: center;
    
  }
  
  
 
  
  
  /* Footer Section */
  
  /* .about-header{
    background-image: url("about.jpg") ;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 300px;
    width:1440px;
    font-family: 'poppins';
    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
    
  }
  .about-1-sec{
    display:flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      gap:150px;
  }
  .about1-para{
    color:black;
    font-family: 'poppins';
    justify-content: center;
    width:410px;
  
  }
  .about1-head{
    color:#1C8FE3;
    font-family: 'poppins';
  }
  .about-2-sec{
    display:flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      gap:150px;
  }
  .about2-para{
    color:black;
    font-family: 'poppins';
    justify-content: center;
    width:410px;
  
  }
  .about2-head{
    color:#1C8FE3;
    font-family: 'poppins';
  }
  .about-3-list{
    color:black;
    font-family: 'poppins';
  } */
  
  /* Result-Section */
  
  /* .result-section{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
    background-color: #1C8FE3;
    margin-top: 40px;
    height:200px;
  }
  .result-no{
    color:white;
    font-family:'poppins';
    font-size: 48px;
    font-weight: 400;
    text-align: center;
    line-height: 5px;
  }
  .result-heading{
    color:white;
    font-family:'poppins';
    font-size: 24px;
    font-weight: 400;
    text-align: center;
  }
  .values-section{
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .value-head{
    color:#1C8FE3;
    font-family: 'poppins';
    font-size:32px;
    font-weight: 500;
    text-align: center;
    line-height: 10px;
  }
  .value-para{
    color:black;
    width:1000px;
    text-align: center;
    font-size: 16px;
    font-family: 'poppins';
  }
  .service-header{
    background-image: url("service.webp") ;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 300px;
    width:1440px;
    font-family: 'poppins';
    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
    
  }
  
  .service-home-link{
    text-align: center;
    font-size: 20px;
    font-family: 'poppins';
    color: black;
    font-weight: 500;
    font-family:'poppins';
  }
  .service-para{
    color: black;
    font-family: 'poppins';
    width: 800px;
    text-align: center;
    
  }
  .service-income{
    font-family: 'poppins';
    text-transform: uppercase;
    text-align: center;
  }
  .service-content{
    display: flex;
    text-align: center;
    justify-content: center;
  }
  .subscribe-section{
    color: black;
  }
  
  .subscribe-section{
    background-color: lightgray;
    display: flex;
    align-items: center;
    justify-content: center;
    height:300px;
    flex-direction: column;
   gap:20px;
    
  }
  .email-portion{
    display:flex;
    gap:50px;
  }
  .subscribe-email-box{
    height: 10px;
    width: 400px;
    align-items: center;
    justify-self: center;
  }
  .Team-header{
    background-image: url("Team.jpg") ;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    height: 400px;
    width:1440px;
    font-family: 'poppins';
    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
    
  } */
  
  /* team card styling */
  
  /* .team-card{
  background-color: #ECECEC;
   height: 465px;
   width : 360px;
   margin-top: 50px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   line-height: 8px;
  
  }
  .team-img{
    height: 216px;
    width: 216px;
    border-radius: 200px;
  }
  .team-name{
    font-family: 'poppins';
    font-size: 24px;
  }
  .team-des{
    font-family: 'poppins';
    font-size: 20px;
    color: #1C8FE3;
  }
  .team-exp{
    font-family: 'Lato';
    font-size: 16px; 
    color: black;
  
  }
  .team-ese{
    font-family: 'Lato';
    font-size: 16px;  
    color: black;
  }
  .team-explore{
    font-family: 'Lato';
    font-size: 16px;  
    color: #1C8FE3;
  }
  .team-align{
    display: flex;
    justify-content: center;
    gap: 50px;
  }
  .card-bar{
  background-color:#1C8FE3;
  width: 360px;
  height:8px;
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 30px;
  }
  .client-hero{
    background-image: url("client.webp") ;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    height: 400px;
    width:1440px;
    font-family: 'poppins';
    display: flex;
    align-items: center;
    justify-content: center;
    color:white; 
  }
  .client-highlight-para{
    font-family: 'poppins';
    font-size: 20px;
    font-weight: 500;
    color:black;
    text-align: center;
    width: 800px;
    margin-left: 100px;
    
   
  }
  .client-industry{
    font-family: 'poppins';
    font-size: 20px;
    width: 286px;
    text-align: center;
    font-weight: 500;
  }
  .client-industry-subcategory{
    font-family: 'poppins';
    font-size: 16px;
    color: #1C8FE3;
    text-align: left;
    margin-right: 70px;
    font-weight: 400;
  }
  .client-card{
    width:373px;
    height: 252px;
    box-shadow: -2px 3px 5px lightgrey ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
  .client-card-align{
    display:flex;
    gap: 50px;
    align-items: center;
    justify-content: center;
  } */
  .blog-hero{
    /* background-image: url("blog-background.jpg") ; */
    /* background-color: white; */
    /* background-repeat: no-repeat; */
    /* background-size: cover; */
    /* background-position: center; */
    height: 150px;
    width:100%;
    font-family: 'montserrat';
    display: flex;
    align-items: center;
    justify-content: center;
    color:#044CAC; 
  } 
  
  /* Blog Section */
  .blog-image{
    height: 450px;
    width: 800px;
  }
  .blog-align{
    margin-left: 60px;
    /* margin-top: 100px; */
    line-height: 25px;
    width:850px;
    font-family: 'montserrat';
    
  }
  .blog-category{
   
    /* font-family: 'poppins'; */
    color:grey;
  }
  .blog-heading{
    /* font-family: 'poppins'; */
    margin-top: 5vh;
    font-weight: 600;
  }
  .blog-content{
    color: black;
    width: 820px;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    /* font-family: 'poppins'; */
  }
  .blog-readmore{
    color:#1C8FE3;
    font-weight: 500;
    /* font-family: 'poppins'; */
    font-weight: 500;
  
  }
  .blog-link {
    text-decoration: none;
  }
  
  .blog-link :hover{
   text-decoration:underline;
 

  }
  

  .blog-date{
    color:grey;
    display: flex;
    font-weight: 500;
    /* font-family: 'poppins'; */
  }
  .blog-date-section{
    display: flex;
    justify-content: space-between;
    width: 820px;
  
  }
  
  .blog-container{
    display: grid;
    grid-template-columns: 60% 20%;
    gap:10vw;
    margin-top: 0;
    margin-bottom: 50vh;

  }
  
  .blog-search{
    margin-top: 100px;
    display: flex;
    align-items: center;
   
  }
  .blog-search-icon{
  position:absolute;
  margin-left: 280px;
  fill: grey;
  height: 30px;
  width:30px
  }
  .blog-search input{
    width: 300px;
    height: 50px;
    border-radius: 100px;
    background-color: #f5f5f5;
    border: none;
    padding-left:30px ;
    color: grey;
  }
  .blog-search input::placeholder{
    font-size: 18px;
    font-family: 'lato';  
  }
  .horizontal-line{
  background-color: #1C8FE3;
  height: 4px;
  width: 100px;
  margin-left: 0px;
  margin-top: 2vh;
  
  }
  .blog-category{
    /* font-family: 'poppins'; */
    color: black;
    font-weight: 600;
    font-size: 22px;
    margin-top: 4vh;
  }
  .blog-sidebar{
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .blog-category-list{
    color: #3C84E4;
    /* font-family: 'lato'; */
    font-size: 16px;
    list-style: none;
    font-weight: 500;
    margin-left: 0;
    padding-left: 0;
   
  }
  .blog-category-list li{
    margin-bottom: 20px;
    margin-top: 2vh;
    
  }
  .category-card-section{
    background-color: #f5f5f5;
    padding-left: 20px;
    width: 320px;
    height: 740px;
  }
  .recent-post-section{
    background-color: #f5f5f5;
    padding-left: 20px;
    width: 380px;
    height: 500px;
    font-family: 'montserrat';
  }
  .Card2{
    height: 259px;
    width:425px;
    border-radius: 20px;
    background-color: #1c8fe3;
    text-align: center;
    justify-content: center;
    line-height: 100%;
    margin: 30px;
    box-shadow: -10px 20px 10px lightgray;
    background : linear-gradient(45deg ,#1c8fe3 , white); /* by default : top to bottom */
    margin-top: 50px;
   
  }
  .mission-img{
    height:50px;
    width: 90px;
    margin-top: 30px;
  }
  .mission-heading{
    font-family:'poppins';
    color:white ;
    font-size:32px;
  }
  .mission-text{
    width:342px;
    font-family:'poppins';
    color:white ;
    font-size:15px;
    margin-left: 30px;
  }
  .mission-readmore{
    font-family: 'lato';
    color:white ;
    font-size :15px;
  }
  .card2-align{
  display:flex;
  margin-left: 50px;
  }
  .team-section{
    text-align: center;
  }
  .team-heading{
    font-family: 'poppins';
    font-size: 32px;
    color: black;
    font-weight: 500;
  }
  .team-heading span{
    color:#1c8fe3;
  }
  .dir-image{
    
    border-radius: 100%;
    margin-top:35px;
    margin-left: 40px;
    margin-right: 40px;
  }
  .dir-align{
    line-height: 80%;
  }
  .dir-name{
    font-family: 'poppins';
    font-size: 20px;
    color: black;
  }
  .dir-qua{
    font-family: 'poppins';
    font-size: 20px;
    color: #1c8fe3;
  }
  .dir-multi{
    display:flex;
    align-items: center;
    justify-content: center;
    
  }
  .recent-blog-card{
    height: 435px;
    width: 390px;
    background-color: white;
    display : flex;
    flex-direction: column;
    gap:10px;
    align-items: center;
    margin-top: 30px;
  
  }
  .blog-home-head{
  font-family: 'poppins';
  }
  .recent-blogs-portion{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    height: 645px;
    margin-top: 50px;
    margin-bottom: 80px;
  }
  .blog-post-img{
    height:233px;
    width:390px
  }
  .blog-text{
    color:black;
    font-family: 'poppins';
    width: 342px;
    text-align: center;
  }
  .blog-home-align{
  display: flex;
  gap: 30px;
  align-items: center;
  }
  .contact-hero{
    /* background-image: url("Contact.jpg") ; */
    /* background-repeat: no-repeat; */
    /* background-size: cover; */
    /* background-position: top; */
    height: 120px;
    width:1440px;
    font-family: 'poppins';
    display: flex;
    align-items: center;
    justify-content: center;
    color:white; 
  }
  .contact-card{
    line-height: 180%;
    color: white;
    font-family: 'lato';
    list-style-type: none;
    font-size:16px;
  }
  .contact-infocard{
    height: 500px;
    width:490px;
    background-color: #0D588F;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    gap: 20px;
    margin-left: 10vw;

  }
  .contact-line{
    background-color:white;
    width: 200px;
    height:3px;
    /* margin-top: -20px; */
    margin-left: -11vw;
    justify-self: left;
    text-align: left;
    ;
  }
  .contact-infocard h1 {
    color :white;
    font-family: 'poppins';
    font-weight: 400;
    font-size: 30px;
    text-align: left;
    margin-top: 10vh;
    margin-left: -4vw;
  }
  .contact-card li {
    line-height: 50px;
    font-size: 20px;
    text-align: left;
  }
  .contact-row-1{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
   
  }

.contact-card div{
  display: flex;
  align-items: start;
  gap:15px;

}
  .contact-map{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 100px;
  }
  .contact-map h1 {
    font-family: 'Montserrat';
    font-weight: 600;
  }

@media(max-width:480px){
  .service-heading{
    font-size: 30px;
  }
  .contact-hero{
    height: 10vh;
    width :100%;
  }
  .contact-row-1{
    flex-direction: column;
    align-items: center;
    justify-content:flex-start ;
    gap:8vh;
    margin-left: 0;
  }
  .contact-infocard{
    height:70vh;
    width:80vw;
    gap: 12px;
    display: flex;
    align-items:center ;
    justify-content: center;  
    margin-left: 0;
  }
  .contact-infocard h1 {
    font-size: 21px;
    
  }
  .contact-card li{
    font-size: 17px;
  }
}

  /* scroll to top */
  /* src/ScrollToTop.css */


  /* Blog Content */
.content-blog-container{
  margin-top:10vh;
  display: grid;
  grid-template-columns:60% 30%;
  gap:10vw;
  margin-bottom: 20vh;
justify-content: center;
}
.blog-contents img{
 height:450px;
 width: 800px;
} 
.blog-contents{
  margin-left: 5vw;
  font-family: 'montserrat';
}
.blog-contents h1{
  width: 100%;
  font-size: 37.5px;
  margin-bottom: 5vh;
  font-family: 'montserrat';
  font-weight: 600;
}
.table-of-content-list{
  font-family: 'montserrat';
  font-size: 18px;
  font-weight: 500;
  line-height: 5vh;
}

.blog-contents h2{
  margin-top: 10vh;
  margin-bottom: 5vh;
  font-weight: 600;
  font-family: 'montserrat';
}
.blog-contents ol ul {
  list-style: none;
}

.content-part{
  font-family: 'montserrat';
}
.content-part p{
  font-size: 16px;
  font-weight: 500;
}
.content-part h4{
 
  font-weight: 600;
}

.blog-content-date{

  font-size: 20px;
  font-weight: 500;
  color:grey;
}

@media(max-width:480px){
  .blog-container{
    display:flex;
    flex-direction: column;
    grid-template-columns: none;
    margin-bottom: 20vh;
  }
  .blog-image{
    height: 100%;
    width: 100%;
  }
  .blog-align{
    margin-left: 0;
    width: 100%;
  }
  .blog-heading{
    font-size:1.5em;
  }
  .blog-content{
    width: 100%;
    font-size: 14px;
  }
  .blog-readmore{
    font-size:1em;
  }
  .blog-date-section{
    width: 100%;
    padding-right:2vw;
  }
  .blog-date{
    font-size: 0.9em;
  }
  .recent-post-section{
    width: 100%;
  }
  .blog-category{
    font-size: 1.1em;
  }
  .horizontal-line{
    height: 3px;
  }
  .blog-category-list{
    font-size: 0.9em;
  }
  .content-blog-container{
    display:flex;
    flex-direction: column;
    grid-template-columns: none;
  }
  .blog-contents{
    margin: 0;
  }
  .blog-contents h1{
    font-size: 1.7rem;
    width: 100%;
  }
  .blog-contents img{
    height: 100%;
    width:100%;
  }
  .blog-content-date{

    font-size: 16px;
    font-weight: 500;
    color:grey;
  }
  .table-of-content-list{
    font-family: 'montserrat';
    font-size: 15px;
    font-weight: 500;
    line-height: 4vh;
  }
  .blog-contents h2{
    margin-top: 3vh;
    margin-bottom: 2vh;
    font-weight: 600;
    font-family: 'montserrat';
    font-size: 1.3rem;
  }
  .content-part p{
    font-size: 14px;
    font-weight: 500;
  }
}

.Thankyou-container{
  font-family: 'montserrat';
}

.thankyou-header{
 background-color: #044CAC;
 height:8vh;

}
.thankyou-msg{
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #044CAC;
  gap:1.5vh;
}
.thankyou-msg h1{
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
}
.thankyou-msg p{
  font-weight: 400;
  text-align: center;
}
.thankyou-msg button{
  color: white;
  background-color: #044CAC;
  border-style: none;
  padding: 1vh 3vw;
  border-radius: 30px;
}
.modal-backdrop.show{
  display: none;
}

/* About Us */

.aboutus-container{
  /* background-image: url("Contact.jpg") ; */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  /* background-position: top; */
  height: 120px;
  width:100%;
  font-family: 'poppins';
  display: flex;
  align-items: center;
  justify-content: center;
  color:white; 
}
.aboutus-sec1{
  font-family:'montserrat';
  display: flex;
  gap:10vw;
  align-items: center;
  justify-content: center;
  padding: 10vh 8vw;
}
.aboutus-sec2,.aboutus-sec2copy{
  font-family:'montserrat';
  display: flex;
  gap:10vw;
  align-items: center;
  justify-content: center;
  padding: 10vh 8vw;
}
.aboutus-sec2copy{
  display: none;
}
.aboutus-sec3,.aboutus-sec3copy{
  font-family:'montserrat';
  display: flex;
  gap:10vw;
  align-items: center;
  justify-content: center;
  padding: 10vh 8vw;
}
.aboutus-sec3copy{
  display: none;
}

.aboutus-image1 , .aboutus-content1{
  width: 50%;
}
.aboutus-content1 h2{
  font-size: 36px;
  font-weight: 700;
  color: #044CAC;
  margin-bottom: 1vh;

}
.aboutus-content1 h3{
  
  font-size: 17px;
  font-weight: 500;
  line-height: 1.5;
  color: #044CAC;
  text-align: left;
}
.about-vision-img{
  height: 100%;
  width: 100%;
}
.about-hotel-img{
  height: 100%;
  width: 100%;
}
.about-mission-img{
  height: 100%;
  width: 100%;
}
.about-hotel-img:hover {

transform: scale(1.01);
transition: transform 0.1s ease-in;
}
.about-vision-img:hover {

  transform: scale(1.01);
  transition: transform 0.1s ease-in;
}
.about-mission-img:hover {

  transform: scale(1.01);
  transition: transform 0.1s ease-in;
}

.about-us-cover{
  background-color: rgba(14, 92, 188, 0.7); 
  width: 100%;
  height:430px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin:12vh 0;
  margin-bottom: 30vh;
  /* opacity: 20%; */
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1;
   scroll-behavior: smooth;
}
.about-us-cover img{
  width: 100%;
  z-index: -1;

}
.about-us-cover h2{
  color: white;
  font-size: 1.35rem;
  font-family: 'montserrat';
  font-weight: 500;
  line-height: 1.8;
  width:72%;
  text-align: center;
  position: absolute;
}
.about-us-cover::-webkit-scrollbar {
  display: none; /* Hides scrollbar in Chrome, Safari, and Opera */

}

/* About us page Responsive */
@media(max-width:480px){

  .aboutus-sec1,.aboutus-sec2copy, .aboutus-sec3copy{
   flex-direction: column;
   padding: 3vh 1vw;
  }
  .aboutus-image1 , .aboutus-content1{
    width: 95%;
  }
  .aboutus-sec2{
    display: none;
   }
   .aboutus-sec2copy{
     display: flex;
     flex-direction: column;
   }
  .aboutus-sec3{
   display: none;
  }
  .aboutus-sec3copy{
    display: flex;
    flex-direction: column;
  }
  .aboutus-content1 h2{
    font-size: 32px;
    font-weight: 700;
    color: #044CAC;
    margin-bottom: 1vh;
    text-align: center;
  }
  .aboutus-content1 h3{
    
    font-size: 15px;
    font-weight: 500;
    line-height: 1.5;
    color: #044CAC;
    text-align: center;
    
  }
  .about-us-cover{
    background-color: rgba(14, 92, 188, 0.7); 
    width: 100%;
    height:350px;
  }
  .about-us-cover img{
    width: 100%;
    height:100%;
  }
  .about-us-cover h2{
    color: white;
    font-size: 1.1rem;
    width:80%;
  }
}

/* Carousel */
/* .left-button,.right-button{
  color: #044CAC;
  width:50%;
  height: 50%;
} */
.section-carousel{
  width: 100% !important;
}

.image-container img{
  width:auto;
  height:500px;
  margin-right: -10vw;
}


.carousel-item{
  display: flex;
  align-items: center;
  justify-content: center;
}
#carouselExampleRide{
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-section-left  button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap:1vw;
  
}

.hero-section-left  a {
  text-decoration: none;
}

@media (max-width:480px) {
  .image-container img{
    width:100%;
    height:100%;
  }
}